<template>
  <div class="settings_manufacturing_boxes">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item :active="tab=='systemreferences'" v-on:click="tab='systemreferences'">System References</b-nav-item>
    </b-nav>

    <systemreferences v-if="tab=='systemreferences'"></systemreferences>

  </div>
</template>

<script>
import systemreferences from '@/components/manufacturing.settings/boxes.systemreferences'

export default {
  name: 'SystemReferences',
  components: {
    systemreferences,
  },
  data () {
    return {
      tab: '',
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Manufacturing',
          to: '/settings/manufacturing'
        },
        {
          text: 'Boxes',
          to: '/settings/manufacturing/boxes'
        },
        {
          text: 'System References',
          active: true
        }
      ],
      loadedProduct: null
    }
  },
  async mounted() {
    this.tab = 'systemreferences'
  }
}
</script>
